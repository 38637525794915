import React from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby"

const Explainer = ({headline, dek, icon, sections, cards, para}) => (

  <section class="section bg-light" id="features">
    { !!headline ?
    <div class="container-sm">
      <div class="row justify-content-center text-center mt-5 mb-5">
          <div class="col-lg-12">
              <i class={`${icon} title-icon text-muted`}></i>
              <h2>{headline}</h2>
              <p class="text-muted mt-3 title-subtitle mx-auto">{dek}</p>
          </div>
      </div>
    </div>
    : <></> }
    { !!para ?
    <div class="container-sm">
      <div class="row justify-content-center mt-4 mb-5 pb-5">
          <div class="col-lg-12 text-muted">
              {para}
          </div>
      </div>
    </div>
    : <></> }
    { !! sections ?
    <div class="container flipflop">

        {sections.map((section, index)=>(
          <div key={index} class="row mt-5 vertical-content">
              <div class="col-lg-6 mt-2 mb-5">
                  <div class="mx-3">
                    { !!section.image ? section.image : <></>}
                  </div>
              </div>
              <div class="col-lg-5 offset-lg-1 mt-2 mb-5">
                  <div class="features-desc">
                      <h2>{section.heading}</h2>
                      <p class="title-subtitle text-muted mt-3">{section.text}</p>
                      { !!section.cta ?
                        <Link to={section.ctaLink} className="btn btn-cta mt-3 intercom">{section.cta}</Link>
                      : <></>}
                  </div>
              </div>
          </div>
        ))}
    </div>
    : <></> }
    { !!cards ?
    <div class="container">
      <div class="row mt-5 align-content-stretch">
        {cards.map((card, index) => (
          <div key={index} class="col-lg-4">
              <div class="bg-white team-box mt-3 pl-4 pr-4 pt-4 pb-4">
                  <i class={`${card.icon} title-icon text-muted`}></i>
                  <div class="team-name">
                      <h5 class="services-title font-weight-bold mb-3">{card.heading}</h5>
                      <p class="text-muted">{card.text}</p>
                  </div>
              </div>
          </div>
        ))}
      </div>
    </div>
    : <></>}
  </section>
)

Explainer.propTypes = {
  headline: PropTypes.string,
  dek: PropTypes.string,
  icon: PropTypes.string,
  sections: PropTypes.array
}

export default Explainer
