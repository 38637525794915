import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"

import SEO from "../components/seo"
import Hero from "../components/hero"
import Features from "../components/features"
import Contact from "../components/contact"
import Explainer from "../components/explainer"
import Cards from "../components/cards"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero
      headline={(<span>We help growing stores stand <u>toe to toe</u> with retail giants.</span>)}
    />
    <Explainer
      icon="ti-book"
      headline={(<span>Reliable tech for growing stores.</span>)}
      dek="When you've outgrown task sites and freelancers, and you don't need the overhead of an agency, we're here for you."
      sections={[
          {
            heading:"Global reach",
            text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          },
          {
            heading:"Giving something back",
            text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          },
          {
            heading:"Expert team",
            text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          }
      ]}
    />

    <Cards
      headline={<span>Welcome to a <u>values</u> driven team</span>}
      dek="We believe that creating a great culture is the best gravity field for great customers and great people."
      cards={[
          {icon: "ti-thumb-up", heading: "Make it easy", text:"We make things easy, even when times are hard."},
          {icon: "ti-medall", heading: "Finish", text: "We take every challenge all the way to the finish line."},
          {icon: "ti-world", heading: "Improve the world", text: "We leave everything a little bit better than we found it."},
          {icon: "ti-face-smile", heading: "Bring the love", text: "We find the passion in our work, our customers, and our teammates."},
          {icon: "ti-crown", heading: "Lift each other up", text: "we provide constructive feedback and actively create a judgement free company"},
          {icon: "ti-check-box", heading: "Be predictable", text: "We call the shots, then make them, we communicate proactively on status"},
          {icon: "ti-star", heading: "Be smart", text: "We don’t always have all the information but we use our intuition and smarts to do the right thing"},
          {icon: "ti-shield", heading: "Be humble", text: "We always remember who the heros are. We are Albert, our customers are Batman."}
      ]}
    />
    <Contact/>
  </Layout>
)

export default IndexPage
